export const showCookieSettings = () => {
  if (typeof window === 'undefined' || !('OneTrust' in window)) {
    return
  }

  ;(window.OneTrust as any).ToggleInfoDisplay()

  const modalOverlayDatenschutz = document.querySelector<HTMLElement>(
    '#onetrust-consent-sdk > .onetrust-pc-dark-filter',
  )

  if (modalOverlayDatenschutz) {
    modalOverlayDatenschutz.classList.add('show')
    const closeBtn = document.getElementById('close-pc-btn-handler')
    if (closeBtn) {
      closeBtn.addEventListener(
        'click',
        () => {
          modalOverlayDatenschutz.classList.remove('show')
        },
        { once: true },
      )
    }
  }
}
